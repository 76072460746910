@import '~leaflet/dist/leaflet.css';
@import '~leaflet-draw/dist/leaflet.draw.css';

.leaflet-container {
  height:100vh;
}

.map {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 90vh;
}

.slider {
  position: absolute;
  top: 0;
  right: 10px;
  width: 800px;
}

.slider input {
  width: 100%;
}
